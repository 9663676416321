import Head from 'next/head';
import {useGeneralStore} from "../stores/general";
import Header from "./inc/header";
import Footer from "./inc/footer";

export default function Layout({ children, title }) {
	const showGlobalLoading = useGeneralStore((state) => state.showGlobalLoading)
	
	return (
		<>
			<Head>
				<meta charset="utf-8" />
				<meta httpEquiv="X-UA-Compatible" content="IE=edge" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				{ title &&
					<title>{title}</title>
				}
			</Head>
			
			<Header />
			
			{!showGlobalLoading &&
				children
			}
			
			<Footer />
		</>
	);
}
