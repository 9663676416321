import { create } from "zustand";

export const useGeneralStore = create((set) => ({
	
	contentMenu: false,
	toggleContentMenu: () => set((state) => ({ contentMenu: !state.contentMenu})),
	
	showGlobalLoading: false,
	setShowGlobalLoading: (showGlobalLoading) => set({ showGlobalLoading })
}))
